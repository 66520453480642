import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  eligiblePaymentMethodDetailSelector,
  orderPayment,
  getAllOrderPayments,
  disableInteractionSelector,
  fulfillmentSelector,
} from "@ultracommerce/react-storefront/global";
import { useTranslation } from "react-i18next";
import { useCheckoutUtilities } from "@ultracommerce/react-storefront/global";
import { addPayment, removeOrderPayment } from "@ultracommerce/react-storefront/global";
import { toast } from "react-toastify";

import { getErrorMessage } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { Button } from "@ultracommerce/react-storefront/global/src/components";
import { StripeSubmitButton, isStripePayment } from "@ultracommerce/react-storefront/global/src/components/Checkout/Payment/StripePayment";

import { shouldAutoSelectPayment } from "@ultracommerce/react-storefront/global/src/components/Checkout/Payment/Payment";
import { isPaypalCommercePayment } from "@ultracommerce/react-storefront/global/src/components/Checkout/Payment/PayPalCommercePayment";
import { PayPalCommercePayment } from "./PayPalCommercePayment"

const PaymentSlide = ({ cartState, placeOrder }) => {
  const {
    CommonModule: {
      TillPayments,
      SwRadioSelect,
      Overlay,
      PaymentList,
      CreditCardPayment,
      TermPayment,
      PayPalPayment,
      GiftCardPayment,
      StripePayment
    },
  } = useElementContext();
  const disableInteraction = useSelector(disableInteractionSelector);
  const fulfillment = useSelector(fulfillmentSelector);
  const cart = useSelector((state) => state.cart);
  const eligiblePaymentMethodDetails = useSelector(eligiblePaymentMethodDetailSelector);
  const { paymentMethod } = useSelector(orderPayment);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    !paymentMethod?.paymentMethodID && shouldAutoSelectPayment(eligiblePaymentMethodDetails)
      ? eligiblePaymentMethodDetails[0]
      : "",
  );
  const [paymentMethodOnOrder, setPaymentMethodOnOrder] = useState(false);
  const [isEditExistingPayment, setEditExistingPayment] = useState(false);
  const allPayments = useSelector(getAllOrderPayments);
  const { isFetching, orderPayments } = cart;
  const [orderProperties, setOrderProperties] = useState({
    OrderDeliveryInstructions: cart.deliveryInstructions,
    customerReferenceNumber: cart.customerReferenceNumber,
    orderNotes: cart.orderNotes,
  });
  const payment = useSelector(orderPayment);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    EXTERNAL_PAYMENT_CODE,
    CREDIT_CARD_CODE,
    GIFT_CARD_CODE,
    TERM_PAYMENT_CODE,
    PAYPAL_PAYMENT_CODE,
    CASH_PAYMENT_CODE,
    CHECK_PAYMENT_CODE,
    PAYPAL_COMMERCE_CODE,
    getPaymentMethodByIDFromList,
  } = useCheckoutUtilities();

  const processSimplePayment = (value) => {
    dispatch(
      addPayment({
        newOrderPayment: {
          saveShippingAsBilling: 1,
          paymentMethod: {
            paymentMethodID: value,
          },
        },
      }),
    );
  };

  useEffect(() => {
    if (paymentMethod && paymentMethod.paymentMethodID && paymentMethodOnOrder !== paymentMethod.paymentMethodID) {
      setPaymentMethodOnOrder(paymentMethod);
      setSelectedPaymentMethod(paymentMethod);
      setEditExistingPayment(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);
  return (
    <Overlay
      active={isFetching}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(0, 0, 0, 0)",
        }),
        spinner: (base) => ({
          ...base,
          width: "100px",
          "& svg circle": {
            stroke: "rgba(211, 211, 211)",
          },
        }),
      }}
      spinner
    >
      {/* <!-- Payment Method --> */}
      <PaymentList
        payments={allPayments}
        disableInteraction={disableInteraction}
        onRemovePayment={(paymentSelection) => {
          setPaymentMethodOnOrder("");
          setSelectedPaymentMethod("");
          setEditExistingPayment(false);
          dispatch(removeOrderPayment({ params: paymentSelection })).then((response) => {
            if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length)
              toast.error(getErrorMessage(response.success().errors));
            else {
              if (shouldAutoSelectPayment(eligiblePaymentMethodDetails)) {
                setSelectedPaymentMethod(eligiblePaymentMethodDetails[0]);
              }
            }
          });
        }}
        onEditDetails={(paymentSelection) => {
          dispatch(removeOrderPayment({ params: paymentSelection })).then((response) => {
            if (response.isSuccess()) {
              if (Object.keys(response.success()?.errors || {}).length) {
                toast.error(getErrorMessage(response.success().errors));
              } else {
                setEditExistingPayment(false);
                if (shouldAutoSelectPayment(eligiblePaymentMethodDetails)) {
                  setSelectedPaymentMethod(eligiblePaymentMethodDetails[0]);
                }
              }
            }
          });
          const paymentToEdit = eligiblePaymentMethodDetails.find(
            (paymentMethod) => paymentMethod.paymentMethodID === paymentSelection.paymentMethod?.paymentMethodID,
          );
          if (paymentToEdit) {
            setEditExistingPayment(true);
            setPaymentMethodOnOrder(paymentToEdit.value);
            setSelectedPaymentMethod(paymentToEdit);
          }
        }}
      />
      {(allPayments.length === 0 || isEditExistingPayment) && (
        <>
          <div className="row mb-3">
            <div className="col-sm-12">
              {eligiblePaymentMethodDetails.length === 0 && (
                <div className="alert alert-warning" role="alert">
                  {t("frontend.checkout.noPaymentEnabled")}
                </div>
              )}
              {eligiblePaymentMethodDetails.length > 0 &&
                !(shouldAutoSelectPayment(eligiblePaymentMethodDetails) &&selectedPaymentMethod) && (
                <SwRadioSelect
                  label={t("frontend.checkout.payment.select")}
                  options={eligiblePaymentMethodDetails}
                  onChange={(value) => {
                    const foundPaymentMethod = getPaymentMethodByIDFromList(eligiblePaymentMethodDetails, value);
                    setSelectedPaymentMethod(foundPaymentMethod);
                    setEditExistingPayment(false);
                    if (
                      foundPaymentMethod.paymentMethodType === CASH_PAYMENT_CODE ||
                      foundPaymentMethod.paymentMethodType === CHECK_PAYMENT_CODE
                    ) {
                      processSimplePayment(value);
                    }
                  }}
                  selectedValue={
                    selectedPaymentMethod?.paymentMethodID?.length
                      ? selectedPaymentMethod.paymentMethodID
                      : paymentMethodOnOrder
                  }
                />
              )}
            </div>
          </div>
          {selectedPaymentMethod.paymentMethodType === CREDIT_CARD_CODE && (
            <CreditCardPayment
              isEdit={isEditExistingPayment}
              method={selectedPaymentMethod.paymentMethodID}
              fulfillment={fulfillment}
            />
          )}
          {selectedPaymentMethod.paymentMethodType === GIFT_CARD_CODE && (
            <GiftCardPayment method={selectedPaymentMethod.paymentMethodID} />
          )}
          {selectedPaymentMethod.paymentMethodType === TERM_PAYMENT_CODE && (
            <TermPayment method={selectedPaymentMethod.paymentMethodID} fulfillment={fulfillment} />
          )}
          {isPaypalCommercePayment(selectedPaymentMethod) &&  (
              <PayPalCommercePayment method={selectedPaymentMethod.paymentMethodID} cartState={cartState} />
            )}
          {selectedPaymentMethod.paymentMethodType === EXTERNAL_PAYMENT_CODE &&
            selectedPaymentMethod.paymentIntegration.integrationPackage === PAYPAL_PAYMENT_CODE && <PayPalPayment />}
          {selectedPaymentMethod.paymentMethodType === EXTERNAL_PAYMENT_CODE &&
            selectedPaymentMethod.paymentIntegration.integrationPackage === "tillpayments" && (
              <TillPayments method={selectedPaymentMethod.paymentMethodID} />
            )}
          {isStripePayment(selectedPaymentMethod) && (
            <StripePayment method={selectedPaymentMethod.paymentMethodID} isEdit={isEditExistingPayment} />
          )}
        </>
      )}
      {orderPayments?.length > 0 && (
          <>
            <div className="form-group mb-2p px-3">
              <textarea
                className="form-control UpdatePropertyTextArea"
                rows="2"
                disabled={isFetching}
                id="order-comments"
                value={orderProperties.orderNotes}
                placeholder={"Order notes"}
                onChange={(e) => {
                  e.preventDefault();
                  setOrderProperties({
                    ...orderProperties,
                    orderNotes: e.target.value,
                  });
                }}
              />
            </div>
            <div className="px-3 py-1 mb-2">
              <textarea
                className="form-control UpdatePropertyTextArea"
                rows="2"
                id={`deliveryInstructions-TextArea`}
                disabled={isFetching}
                value={orderProperties.deliveryInstructions}
                placeholder={"Delivery Instructions"}
                onChange={(e) => {
                  e.preventDefault();
                  setOrderProperties({
                    ...orderProperties,
                    OrderDeliveryInstructions: e.target.value,
                  });
                }}
              />
            </div>
            <div className="px-3 py-1 pb-2">
              <textarea
                className="form-control UpdatePropertyTextArea"
                rows="2"
                id={`customerReferenceNumber-TextArea`}
                disabled={isFetching}
                value={orderProperties.customerReferenceNumber}
                placeholder={"Customer Reference Number"}
                onChange={(e) => {
                  e.preventDefault();
                  setOrderProperties({
                    ...orderProperties,
                    customerReferenceNumber: e.target.value,
                  });
                }}
              />
            </div>
            {cart.orderRequirementsList.includes("payment") ? (
              <p className="text-danger px-4">
                The order amount has changed, please remove and add the PayPal
                payment details again.
              </p>
            ) : null}
            <div className="px-3">
              {isStripePayment(payment?.paymentMethod) ? (
                <StripeSubmitButton />
              ) : (
                <Button
                  disabled={isFetching || !!cart.orderRequirementsList}
                  isLoading={isFetching}
                  label={t("frontend.order.complete")}
                  classList="btn btn-primary w-100 mt-3 mb-2"
                  onClick={placeOrder}
                />
              )}
              </div>
          </>
        )}
    </Overlay>
  );
};

export { PaymentSlide };